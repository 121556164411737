import React,{useEffect} from 'react'
import "./blog.css"

import Aos from 'aos';
import 'aos/dist/aos.css';
import img11 from "../../assets/cel_3.jpg"
import img12 from "../../assets/cel_4.jpg"
import img13 from "../../assets/cel_5.jpg"
import img14 from "../../assets/cel_2.jpg"

import {BsArrowRightShort} from 'react-icons/bs';

//Importar Imagenes

const Post = [
  {
    id: 1,
    postImage: img11,
    title: 'Disfruta Malecón de Puerto Vallarta',
    desc: ' Paseo peatonal con vistas al mar, arte y opciones gastronómicas.',
  },
  {
    id: 2,
    postImage: img13,
    title: 'Visita la Zona Romántica',
    desc: 'Ambiente bohemio, vida nocturna y playa Los Muertos.',
  },
  {
    id: 3,
    postImage: img12,
    title: 'Descubre el Templo de Nuestra Señora Guadalupe',
    desc: 'Paraíso natural, snorkel, Playa Escondida.',
  },
  {
    id: 4,
    postImage: img14,
    title: 'Importantes eventos culturales todo el año',
    desc: 'Pueblo encantador, playas de surf y ambiente relajado.'
  }
]


const Blog = () => {
  useEffect(() => {
    Aos.init({duration: 2000})
  },[])
  return (
    <section className='blog container section'>
      <div className='secContainer'>
        
        <div className='secIntro'>
          <h2 data-aos="fade-up" data-aos-duration="2000" className='secTitle'>
            Lugares de interés para visitar
          </h2>
          <p data-aos="fade-up" data-aos-duration="2500">
            Un vistazo a la increible y satisfactoria experiencia en Mexico.
          </p>
        </div>

        <div className="mainContainer grid">
          {
          Post.map(({id, postImage, title, desc}) => {
            return(
              <div data-aos="fade-up" data-aos-duration="2000" className="singlePost grid">
            <div className="imgDiv">
              <img src={postImage} alt={title}/>
            </div>

            <div className="postDetails">
              <h3 data-aos="fade-up" data-aos-duration="3000">
                {title}
              </h3>
              <p data-aos="fade-up" data-aos-duration="4000">
                {desc}
              </p>
            </div>
            <a href="#" className="flex" data-aos="fade-up" data-aos-duration="4500">
              Leer mas.
              <BsArrowRightShort className="icon"/>
            </a>
          </div>
            )
          })
        }
        </div>
      </div>
    </section>
  )
}

export default Blog
