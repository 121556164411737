import React from 'react'
import '../../../src/app.css';
import Navbar from '../Navbar/Navbar';
import Home from '../Home/Home';
import Popular from '../Popular/Popular'
import Offer from '../Offers/Offers'
import About from '../About/About'
import Blog from '../Blog/Blog'
import Habitaciones from '../Habitaciones/Habitaciones'
import Footer from '../Footer/Footer'
import Vallarta from '../Vallarta/Vallarta'

const Pagina1 = () => {
  return (
    <div className='amarillo'>
      <>
    <Navbar/>
      <Home/>      
      <Offer/>  
      <About/>
      <Vallarta/>    
      <Popular/>
      <Blog/>
      <Footer/>      
    </>
    </div>
  )
}

export default Pagina1
