import React,{useEffect} from 'react'
import "./vallarta.css"

import Aos from 'aos';
import 'aos/dist/aos.css';

//Imagenes
import imagen1 from "../../assets/dos.jpg";
import imagen2 from "../../assets/cuatro.jpg";
import imagen3 from "../../assets/tres.jpg";
//Video
import video from "../../assets/intro.mp4";
const Vallarta = () => {
  
  useEffect(() => {
    Aos.init({duration: 2000})
  },[])

  return (
    <section className='about section'>
      <div className='secContainer'>

        <div className='videoCard container'>
          <div className='cardContent grid'>
            <div data-aos="fade-right" data-aos-duration="2000" className='cardText'>
              <h2>
              Vive la experiencia de disfrutar el paradisiaco Puerto de Vallarta.
              </h2>
              <p>
              Disfruta de la armonía y la naturaleza en nuestro hotel con playa cerca. Relájate y descansa en un ambiente tranquilo y acogedor.
              </p>
            </div>

            <div data-aos="fade-left" data-aos-duration="2000" className='cardVideo'>
              <video src={video} autoPlay loop muted type="video/mp4"></video>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Vallarta
